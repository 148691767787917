import type { FC } from 'react';
import React, { createContext, useMemo, useCallback, useState } from 'react';
import { useQuery } from 'react-apollo';

import { useSessionData } from '@confluence/session-data';
import {
	Attribution,
	ErrorDisplay,
	withErrorBoundary,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { AudienceSegmentationContextProviderQuery } from './AudienceSegmentationContextProviderQuery.graphql';
import type { AudienceSegmentationContextProviderQuery as AudienceSegmentationContextProviderQueryType } from './__types__/AudienceSegmentationContextProviderQuery';

export type AudienceSegmentationProviderProps = {
	isCompanyHub: boolean;
	initialAudienceGroupIds?: string[];
	shouldFetchUserGroups?: boolean;
	children?: React.ReactNode;
};

export type AudienceSegmentationContextValueType = {
	isCompanyHub: boolean;

	currentAudienceGroupIds: string[];
	setCurrentAudienceGroupIds: (groupIds: string[]) => void;
	isTargetAudience: (targetGroupIds: string[]) => boolean;
};

export const ACCESSIBLE_TO_ANYONE_OPTION_ID = 'accessible-to-anyone';

export const AudienceSegmentationContext = createContext<AudienceSegmentationContextValueType>({
	isCompanyHub: false,
	currentAudienceGroupIds: [],
	setCurrentAudienceGroupIds: () => {},
	isTargetAudience: () => true,
});

const AudienceSegmentationContextProviderInternal: FC<AudienceSegmentationProviderProps> = ({
	children,
	isCompanyHub,
	initialAudienceGroupIds = [],
	shouldFetchUserGroups = false,
}) => {
	const [currentAudienceGroupIds, setCurrentAudienceGroupIds] = useState(initialAudienceGroupIds);

	const isTargetAudience = useCallback(
		(targetGroupIds: string[] | undefined) => {
			if (
				!isCompanyHub || // if we are outside of company hub, no filtering
				targetGroupIds === undefined // if no target groups are provided, no filtering
			) {
				return true;
			}
			const audienceGroupIdsSet = new Set(currentAudienceGroupIds);
			if (audienceGroupIdsSet.has(ACCESSIBLE_TO_ANYONE_OPTION_ID)) {
				return false;
			}
			return targetGroupIds.some((allowedGroup) => audienceGroupIdsSet.has(allowedGroup));
		},
		[currentAudienceGroupIds, isCompanyHub],
	);

	const audienceSegmentationContextValue = useMemo(
		() => ({
			currentAudienceGroupIds,
			setCurrentAudienceGroupIds,
			isCompanyHub,
			isTargetAudience,
		}),
		[currentAudienceGroupIds, setCurrentAudienceGroupIds, isCompanyHub, isTargetAudience],
	);

	const { userId } = useSessionData();

	const { error: queryError, loading: queryLoading } =
		useQuery<AudienceSegmentationContextProviderQueryType>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			AudienceSegmentationContextProviderQuery,
			{
				variables: {
					skip: !shouldFetchUserGroups || !isCompanyHub,
					accountId: userId,
				},
				onCompleted: (data) => {
					const userGroupIds: string[] =
						data?.user?.confluence?.groupsWithId
							?.map((result) => result?.id)
							.filter((id): id is string => !!id) ?? [];

					// onCompleted shouldn't be called if we skip the query but this is to ensure
					// the state doesn't get set when the fixture mock gives this query the default value
					shouldFetchUserGroups && setCurrentAudienceGroupIds(userGroupIds);
				},
			},
		);

	const errorDisplay = useMemo(() => {
		if (queryError && !queryLoading) {
			if (isUnauthorizedError(queryError)) {
				markErrorAsHandled(queryError);
			} else {
				return <ErrorDisplay error={queryError} />;
			}
		}
		return null;
	}, [queryError, queryLoading]);

	return (
		<AudienceSegmentationContext.Provider value={audienceSegmentationContextValue}>
			{errorDisplay}
			{children}
		</AudienceSegmentationContext.Provider>
	);
};

export const AudienceSegmentationContextProvider = withErrorBoundary({
	attribution: Attribution.COMPANY_HUB,
})(AudienceSegmentationContextProviderInternal);
